<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar dense class="tc-title">
      <v-toolbar-title>Company Criteria</v-toolbar-title>

      <!-- <template>
                    <v-btn color="primary" class="btn-title" dark  @click="dialog=true"><v-icon>mdi-plus</v-icon>New Tpo Registration</v-btn>
                </template> -->
    </v-toolbar>
    <v-card>
      <v-container fluid>
        <!-- <v-row justify="center">
                    <v-col sm="3">
                        <h3>Company Criteria</h3>
                    </v-col>
                </v-row> -->
        <v-row justify="center" id="cmp_list">
          <v-col sm="3">
            <v-select
              v-model="selected_company"
              item-text="name"
              item-value="id"
              :items="items"
              label="Company"
              @change="getofferinginfo"
              autocomplete="off"
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row justify="center" id="cmp_name">
          <v-col sm="3">
            <span style="color: black; font-size: 12; font-weight: normal"
              >Comapny Name :
              <span style="color: blue; font-size: 16; font-weight: bold">
                {{ this.selected_company }}</span
              >
            </span>
            <!--
            <v-combobox
              v-model="selected_company"
              label="Company"
              @change="getofferinginfo"
              readonly
              dense
            ></v-combobox>
            -->
          </v-col>
        </v-row>
        <template v-if="selected_company != null">
          <v-row>
            <v-col cols="12" sm="6">
              <v-card style="margin: 8px; height: 100%">
                <v-card-title class="c-title"> Company Info. </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Placement Type</span>
                    </v-col>
                    <v-col cols="6" md="6"> : {{ placementtype }} </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Company offering Code </span>
                    </v-col>
                    <v-col cols="6" md="6"> : {{ code }} </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Schedule Date</span>
                    </v-col>
                    <v-col cols="6" md="6"> : {{ schedule_date }} </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Company Type</span>
                    </v-col>
                    <v-col cols="6" md="6"> : {{ companytype }} </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Industry Type</span>
                    </v-col>
                    <v-col cols="6" md="6"> : {{ industrytype }} </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="6">
              <v-card style="margin: 8px; height: 100%">
                <v-card-title class="c-title">
                  Branches and Organization
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Branches</span>
                    </v-col>
                    <v-col cols="6" md="6">
                      <ul id="v-for-object" class="demo">
                        <li v-for="program in branches" :key="program.name">
                          {{ program.name }}
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Organization</span>
                    </v-col>
                    <v-col cols="6" md="6">
                      <ul id="v-for-object" class="demo">
                        <li v-for="org in organization" :key="org.name">
                          {{ org.name }}
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="3"> </v-col>
            <v-col cols="12" sm="6">
              <v-card style="margin: 8px; height: 100%">
                <v-card-title class="btn-title2 link-icon">
                  Company Specific Criteria
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="6" sm="4">
                      <span class="lbl">Live Backlog Allowed</span>
                      <v-switch
                        v-model="backlog"
                        :label="` ${backlog.toString()}`"
                        @change="addspecificcriteria()"
                      ></v-switch>
                    </v-col>
                    <!-- <v-col cols="6" sm="4">
                                <span class="lbl">Specific Criteria</span>
                            </v-col> -->
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="specificcriteria"
                        label="Add Specific Criteria"
                        type="text"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <!-- <v-row justify="center"> -->
                      <v-btn
                        class="btn-title"
                        dark
                        color="indigo"
                        @click="addspecificcriteria()"
                      >
                        <v-icon left>mdi-plus</v-icon>Save</v-btn
                      >
                      <!-- </v-row>    -->
                    </v-col>
                  </v-row>
                </v-card-text>
                
              </v-card>
            </v-col>
          </v-row>
          
        </template>
        <!-- <v-row>
                    

                    <template v-if="selected_company!=null">
                        <v-card>
                            <v-row>
                        <v-col cols="12" sm="4">
                            <v-chip color="green" label text-color="white">Placement Type::{{placementtype}}</v-chip>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-chip color="green" label text-color="white">Schedule Date::{{schedule_date}}</v-chip>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-chip color="green" label text-color="white">Company Type::{{companytype}}</v-chip>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-chip color="green" label text-color="white">Industry Type::{{industrytype}}</v-chip>
                        </v-col>
                            </v-row>
                        </v-card>
                        
                    </template>
                </v-row> -->
        <!-- <template v-if="selected_company!=null">
                    <v-row>
                <v-col cols="12" sm="2">
                            <v-chip color="green" label text-color="white">Branches:</v-chip> -->
        <!--<v-chip class="ma-2" label>
                            Branches:
                        </v-chip> 
                            <v-chip outlined pill style="margin: 5px;" v-for="program in branches" :key="program.name">{{ program.name }}</v-chip>-->
        <!-- <ul id="v-for-object" class="demo">
                                <li v-for="program in branches" :key="program.name">
                                    {{ program.name }}
                                </li>
                            </ul>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-chip color="green" label text-color="white">Organization :</v-chip> -->
        <!-- <v-chip outlined pill>
                            Organization :
                        </v-chip> 
                            <v-chip outlined pill style="margin: 5px;" v-for="org in organization" :key="org.name">{{ org.name }}</v-chip>-->
        <!-- <ul id="v-for-object" class="demo">
                                <li v-for="org in organization" :key="org.name">
                                    {{ org.name }}
                                </li>
                            </ul>
                        </v-col>
                    </v-row>
                </template> -->
        <!-- <v-expansion-panels  v-model="panel" v-if="selected_company!=null" multiple>
                    <v-expansion-panel > -->
        <!-- <v-expansion-panel-header><center><h4>Add Company Criteria</h4></center></v-expansion-panel-header>
                        <v-expansion-panel-content>
                             <v-divider class="mx-4"></v-divider> -->
        <!-- <template v-if="selected_company!=null">
                                <v-card :elevation="0">
                                    <br /> -->
        <!-- <center><h4>Add Specific Criteria</h4></center>
                                    <v-row>
                                        <v-col cols="12" sm="4" style="margin: 10px;">
                                            Live Backlog Allowed:
                                            <v-switch v-model="backlog" :label="` ${backlog.toString()}`" @change="addspecificcriteria()"></v-switch>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <v-text-field v-model="specificcriteria" label="Add Specific Criteria" type="text"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-btn class="mx-1" dark small color="indigo" @click="addspecificcriteria()"> <v-icon left>mdi-plus</v-icon>Save </v-btn>
                                        </v-col>
                                    </v-row> -->
        <!-- <v-divider class="mx-4"></v-divider><br />
                                    <center><h4>Add Criteria</h4></center>
                                    <v-row>
                                        <v-col cols="12" sm="3" style="margin: 10px;">
                                            <v-combobox v-model="selected_degree" :items="degree" label="Degree" dense></v-combobox>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <v-text-field v-model="percentage" label="Percentage" type="number"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <v-text-field v-model="criteria_number" label="Criteria Number" type="number" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-btn class="mx-1" dark small color="indigo" @click="addcriteria()"> <v-icon left>mdi-plus</v-icon>Save </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                
                                <v-row> </v-row>
                               
                            </template>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels> -->

        <v-row v-if="showcriteria" justify="center">
          <v-col sm="12">
            <template>
              <v-card>
                <v-toolbar dense class="tc-title">
                  <v-toolbar-title>Company Criteria</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <!-- <template>
                                                    <v-btn color="primary" class="btn-title" dark  @click="dialog=true"><v-icon>mdi-plus</v-icon>New Tpo Registration</v-btn>
                                                </template> -->
                  <v-spacer></v-spacer>
                  <!-- <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field> -->
                  <template>
                    <v-btn
                      color="primary"
                      class="btn-title"
                      dark
                      @click="dialog_add = true"
                      ><v-icon>mdi-plus</v-icon> Add Company Criteria</v-btn
                    >
                  </template>
                </v-toolbar>
                <v-card-title> </v-card-title>

                <v-data-table
                  :headers="headers"
                  :items="criteria"
                  :search="search"
                >
                  <template v-slot:item.action="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{}">
                        <v-avatar style size="25" class="edit-avatar">
                          <v-icon
                            class="edit-v-icon mr-2"
                            @click="editcriteria(item)"
                            title="edit"
                            style
                            >edit</v-icon
                          >
                        </v-avatar>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-btn text>
                      <v-icon color="red" @click="deleteCriteria(item.id)">
                        mdi-delete</v-icon
                      ></v-btn
                    >
                  </template>
                </v-data-table>
              </v-card>
            </template>
          </v-col>
        </v-row>
      </v-container>
      <template>
        <v-row justify="center">
          <v-dialog v-model="dialog_add" persistent max-width="500px">
            <v-card>
              <v-card-title class="card-title">
                <v-row justify="space-between">
                  Add Company Criteria
                  <v-btn icon dark @click="dialog_add = false">
                    <v-icon color="black">mdi-close</v-icon>
                  </v-btn>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <!-- <v-row>
                      <v-col cols="12" sm="6">
                          <v-select :items="degree_List" label="Education" v-model="savedata.degree" item-text="degree" item-value="id" required></v-select>
                      </v-col>
                      <v-col cols="12" sm="6">
                          <v-text-field placeholder="Enter Name of Qulification" v-model="savedata.degree_name"></v-text-field>
                      </v-col>
                  </v-row>
                  <v-row>
                      <v-col cols="12" sm="6">
                          <v-text-field placeholder="Enter Board/University Name" v-model="savedata.university_name"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                          <v-text-field placeholder="Enter Institue Name" v-model="savedata.institue_name"></v-text-field>
                      </v-col>
                  </v-row>
                  <v-row>
                      <v-col cols="12" sm="6">
                          <v-text-field placeholder="Enter Percentage/CPI" type="number" v-model="savedata.percentage_cpi" hint="Only Enter Number Value"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                          <v-select :items="class_List" label="Class Obtained" v-model="savedata.pass_class" item-text="resultClass" item-value="id" required></v-select>
                      </v-col>
                  </v-row>
                  <v-row>
                      <v-col cols="12" sm="6">
                          <v-text-field placeholder="Enter Passing Year" type="number" v-model="savedata.pass_year"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                          <v-select :items="iscompleted_list" label="Is Degree Completed?" v-model="savedata.is_compl" item-text="name" item-value="id" required></v-select>
                      </v-col>
                  </v-row> -->
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="criteria_number"
                        label="Criteria Number"
                        type="number"
                        required
                        autocomplete="off"
                        :rules="minValueRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-combobox
                        v-model="selected_degree"
                        autocomplete="off"
                        :items="degree"
                        label="Degree"
                        @change="changefieldname"
                        dense
                        :rules="[(v) => !!v || 'required']"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="percentage"
                        autocomplete="off"
                        :label="cpifieldname"
                        type="number"
                        :rules="[(v) => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog_add = false"
                  >Close</v-btn
                >
                <v-btn color="blue darken-1" text @click="addcriteria()"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
      <v-dialog v-model="dialog_edit" max-width="500px">
        <!-- <v-card>
              <v-card-title class="card-title">
                  Edit Criteria
              </v-card-title>
              <v-card-text>
                  <v-row dense>
                      <v-col cols="12">
                          <v-combobox v-model="editedItem.degree" :items="degree" label="Degree" dense></v-combobox>
                      </v-col>
                  </v-row>
                  <v-row dense>
                      <v-col cols="12">
                          <v-text-field dense v-model="editedItem.percentage" label="percentage"></v-text-field>
                      </v-col>
                  </v-row>
                  <v-row dense>
                      <v-col cols="12">
                          <v-text-field dense v-model="editedItem.criteria_number" label="Criteria Number"></v-text-field>
                      </v-col>
                  </v-row>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
                  <v-btn color="primary darken-1" @click="saveEditedCriteria()">Save</v-btn>
              </v-card-actions>
          </v-card> -->
        <v-card>
          <v-card-title class="card-title">
            <v-row justify="space-between">
              Edit Company Criteria
              <v-btn icon dark @click="dialog_edit = false">
                <v-icon color="black">mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-col cols="12">
                <v-text-field
                  dense
                  v-model="editedItem.criteria_number"
                  label="Criteria Number"
                  :rules="minValueRules"
                ></v-text-field>
              </v-col>
              <v-row>
                <v-col cols="12">
                  <v-combobox
                    v-model="editedItem.degree"
                    :items="degree"
                    label="Degree"
                    @change="changefieldnameEdit"
                    dense
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="editedItem.percentage"
                     :label="cpifieldname"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row> </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog_edit = false"
              >Close</v-btn
            >
            <v-btn color="blue darken-1" text @click="saveEditedCriteria()"
              >Update</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="text-center">
         <v-btn
                        class="btn-title ma-5 mt-8 center"
                        dark
                        color="indigo"
                        @click="toAttachment()"
                        >
                       Add Attachment<v-icon>mdi-arrow-left</v-icon> Previous</v-btn>

         <v-btn
                        class="btn-title ma-5 mt-8 center"
                        dark
                        color="indigo"
                        @click="toProcedure()"
                        >
                        Next<v-icon>mdi-arrow-right</v-icon>Selection Procedure</v-btn>

        </div>
     
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    dialog_edit: false,
    dialog_add: false,
    search: "",
    selected_company: null,
    items: "",
    nameidmap: "",
    snackbar: "",
    snackbar_msg: "",
    panel: [0],
    color: "",
    branches: "",
    schedule_date: "",
    companytype: "",
    industrytype: "",
    placementtype: "",
    organization: "",
    degree: "",
    finalDegreeList : [],
    cpifieldname : "Percentage/CPI",
    selected_degree: null,
    percentage: "",
    criteria: "",
    criteria_number: "",
    code:"",
    headers: [
      { text: "Criteria Number", value: "criteria_number", sortable: false },
      { text: "Degree", value: "degree", sortable: false },
      {
        text: "Criteria(percentage/cpi)",
        value: "percentage",
        sortable: false,
      },

      { text: "Action", value: "action", sortable: false },
    ],
    showcriteria: false,
    editedItem: {
      degree: "",
      percentage: "",
      id: "",
      criteria_number: "",
    },
    dialog_edit: false,
    backlog: false,
    specificcriteria: null,
    scheduling_id: null,
    minValueRules: [
      (v) => !!v || "This field is required",
      (v) => (v && v >= 0) || "Criteria Number should be above 1",
    ],
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    editcriteria(item) {
      this.editedIndex = this.criteria.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog_edit = true;
      this.changefieldnameEdit();
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    get_company_list() {
      //this.get_ay_std_ft_loader = true;

      axios
        .post("/TPOCompanyScheduling/getCompanyOfferingList")
        .then((res) => {
          //console.log(res.data);
          if (res.data.msg == "200") {
            this.items = res.data.companylist;
            //console.log("this.items");
            //console.log(this.items);
            this.nameidmap = res.data.nameidmap;
            //this.selected_company=res.data.companylist[0]
          } else {
            //error
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          //close loader
          // this.get_ay_std_ft_loader = false;
        });
    },
    getofferinginfo() {
     // alert(this.selected_company);
    /*  const data = {
        offering: this.nameidmap[this.items[0]],
      };
      if (this.selected_company) {
        data.offering = this.nameidmap[this.selected_company];
      }*/
      
      const data = {
        offering: this.selected_company,
      };
      axios
        .post("/TPOCompanyScheduling/CompanyofferingInfo", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.branches = res.data.branches;
            this.code = res.data.code;
            this.schedule_date = res.data.schedule_date;
            this.companytype = res.data.companytype;
            this.industrytype = res.data.industrytype;
            this.placementtype = res.data.placementtype;
            this.organization = res.data.organization;
            this.degree = res.data.degree;
            this.finalDegreeList = res.data.finalDegreeList;
            this.criteria = res.data.criteria;
            this.specificcriteria = res.data.specificcriteria;
            this.backlog = res.data.backlog;
            this.showcriteria = true;
          } else {
            //error
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          //close loader
          // this.get_ay_std_ft_loader = false;
        });
    },
    toAttachment(){
       this.$router.push({
       name:"tpo-add-attachment",
        params: { scheduling_id: this.$route.params.scheduling_id, cmp_name: this.$route.params.cmp_name, item:this.$route.params.item },
      });
    },
    toProcedure(){
        this.$router.push({
        name: "selection-procedure",
        params: { scheduling_id: this.$route.params.scheduling_id, cmp_name: this.$route.params.cmp_name, item:this.$route.params.item },
      });
    },

    getofferinginfobyid() {
      //console.log("getofferinginfobyid ")
      const data = {
        offering: this.scheduling_id,
      };
      axios
        .post("/TPOCompanyScheduling/CompanyofferingInfobyid", data)
        .then((res) => {
          //console.log(res.data);
          if (res.data.msg == "200") {
            this.branches = res.data.branches;
            this.schedule_date = res.data.schedule_date;
            this.code = res.data.code;
            this.companytype = res.data.companytype;
            this.industrytype = res.data.industrytype;
            this.placementtype = res.data.placementtype;
            this.finalDegreeList = res.data.finalDegreeList;
            this.organization = res.data.organization;
            this.degree = res.data.degree;
            this.criteria = res.data.criteria;
            this.specificcriteria = res.data.specificcriteria;
            this.backlog = res.data.backlog;
            this.showcriteria = true;
          } else {
            //error
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          //close loader
          // this.get_ay_std_ft_loader = false;
        });
    },
    addcriteria() {
      if (
        this.selected_degree == null ||
        this.percentage == "" ||
        this.selected_company == null ||
        this.criteria_number == null
      ) {
        this.showSnackbar(
          "#b71c1c",
          "Degree, percentage and criteria number are compulsory"
        );
        return;
      }
      if(this.cpifieldname == "CPI"&& this.percentage > 10){
          this.showSnackbar(
          "#b71c1c",
          "Please enter CPI value between 1-10"
        );
         return;
      }else if(this.cpifieldname == "Percentage"&& this.percentage > 100){
          this.showSnackbar(
          "#b71c1c",
          "Please enter Percentage value between 1-100"
        );
         return;
      }
      else{
      //console.log("this.selected_degree");
      //console.log(this.selected_degree);
      //console.log("this.percentage");
      //console.log(this.percentage);
      //console.log("[this.selected_company]");
      //console.log(this.selected_company);
      //console.log("this.nameidmap[this.selected_company]");
      //console.log(this.nameidmap[this.selected_company]);
      //console.log("this.criteria_number");
      //console.log(this.criteria_number);

      const data = {
        degree: this.selected_degree,
        percentage: this.percentage,
        offering: this.selected_company,
        criteria_number: this.criteria_number,
      };
      if (this.scheduling_id) {
        data.offering = this.scheduling_id;
      }
      //console.log("sfduihs uhdf shydg fhygs dfy sgdgf gsd gf sdfg sgdf  ")
      //console.log(data)
      this.dialog_add = false;
      axios
        .post("/TPOCompanyScheduling/saveCriteria", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.branches = res.data.branches;
            this.schedule_date = res.data.schedule_date;
            this.companytype = res.data.companytype;
            this.industrytype = res.data.industrytype;
            this.placementtype = res.data.placementtype;
            this.organization = res.data.organization;
            this.degree = res.data.degree;
            this.criteria = res.data.criteria;
            this.specificcriteria = res.data.specificcriteria;
            this.backlog = res.data.backlog;
            this.showcriteria = true;
            this.selected_degree=null;
            this.percentage="",
            this.criteria_number="",
              this.showSnackbar(
          "green",
          res.data.msg1
        );
          } else{
            this.showSnackbar(
          "#b71c1c",
          res.data.msg1
        );
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          //close loader
          // this.get_ay_std_ft_loader = false;
        });
    }
  },
    saveEditedCriteria() {
      if (
        this.editedItem.percentage == null ||
        this.editedItem.criteria_number == null
      ) {
        this.showSnackbar(
          "#b71c1c",
          "percentage and criteria number are compulsory"
        );
        return;
      }
      if(this.cpifieldname == "CPI"&& this.editedItem.percentage > 10){
          this.showSnackbar(
          "#b71c1c",
          "Please enter CPI value between 1-10"
        );
         return;
      }else if(this.cpifieldname == "Percentage"&& this.editedItem.percentage > 100){
          this.showSnackbar(
          "#b71c1c",
          "Please enter Percentage value between 1-100"
        );
         return;
      }
      else{
      const data = {
        degree: this.editedItem.degree,
        id: this.editedItem.id,
        percentage: this.editedItem.percentage,
        criteria_number: this.editedItem.criteria_number,
        company: this.selected_company,
        //offering:this.nameidmap[this.selected_company],
      };
      axios
        .post("/TPOCompanyScheduling/saveEditedCriteria", data)
        .then((res) => {
          if (res.data.msg == "200") {
            if (res.data.code == "SUCCESS") {
              this.criteria = res.data.criteria;
              this.specificcriteria = res.data.specificcriteria;
              this.backlog = res.data.backlog;
             // this.showcriteria = true;
              this.dialog_edit = false;
              this.showSnackbar("#4caf50", "Company Criteria is changed...");
            } else if (res.data.code == "NA") {
              this.showSnackbar("#b71c1c", res.data.message);
            }
          } else {
            //error
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          //close loader
          // this.get_ay_std_ft_loader = false;
        });
    }
    },
    addspecificcriteria() {
      const data = {
        backlog: this.backlog,
        specificcriteria: this.specificcriteria,
        offering: this.nameidmap[this.selected_company],
      };
      if (this.scheduling_id) {
        data.offering = this.scheduling_id;
      }
      axios
        .post("/TPOCompanyScheduling/saveSpecificCriteria", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.criteria = res.data.criteria;
            this.specificcriteria = res.data.specificcriteria;
            this.backlog = res.data.backlog;
            this.showcriteria = true;
            this.showSnackbar(
              "#4caf50",
              "Specific Criteria saved Successfully..."
            );
          } else {
            //error
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          //close loader
          // this.get_ay_std_ft_loader = false;
        });
    },
    deleteCriteria(item) {
      //console.log("item");
      //console.log(item);
      const data = {
        offering: item,
      };
      axios
        .post("/TPOCompanyScheduling/deleteCompanyCriteria", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.criteria = res.data.criteria;
            this.specificcriteria = res.data.specificcriteria;
            this.backlog = res.data.backlog;
            this.showcriteria = true;
            this.showSnackbar("#4caf50", "Deleted Successfully...");
          } else {
            //error
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          //close loader
          // this.get_ay_std_ft_loader = false;
        });
    },

    changefieldname(){
    //  alert("hii in change")
      //console.log("list"+this.finalDegreeList);
       for(var i = 0; i < this.finalDegreeList.length; i++)
       { 
          if(this.selected_degree == this.finalDegreeList[i].name){
             this.cpifieldname = this.finalDegreeList[i].type;
             break;
          }

       }
    },
    changefieldnameEdit(){
       for(var i = 0; i < this.finalDegreeList.length; i++)
       { 
          if(this.editedItem.degree == this.finalDegreeList[i].name){
             this.cpifieldname = this.finalDegreeList[i].type;
             break;
          }
       }
    },
  },
  mounted() {
    document.getElementById("cmp_list").style.display = "block";
    document.getElementById("cmp_name").style.display = "block";
    this.scheduling_id = this.$route.params.scheduling_id;
    //console.log('this.$route.params.cmp_name');
    //console.log(this.$route.params.cmp_name);
    this.code = this.$route.params.code;
    this.selected_company = this.$route.params.cmp_name;
    //console.log("this.scheduling_id");
    //console.log(this.scheduling_id);
     if(this.scheduling_id==undefined)
   {
     this.scheduling_id=localStorage.getItem("scheduling_id")
   }
     if(this.selected_company==undefined)
   {
     this.selected_company=localStorage.getItem("companyname")
   }
    if (this.scheduling_id) {
      this.getofferinginfobyid();
      
      //   document.getElementById("cmp_list").style.display = "block";
      //   document.getElementById("cmp_name").style.display = "none";
      document.getElementById("cmp_list").style.display = "none";
      document.getElementById("cmp_name").style.display = "block";
    } else {
      this.get_company_list();
      document.getElementById("cmp_list").style.display = "block";
      document.getElementById("cmp_name").style.display = "none";
    }
  },
};
</script>
<style scoped>
.ec-title {
  background-image: linear-gradient(-100deg, #f4faba, #d64907);
  color: #fff;
}
.c-title {
  background-image: linear-gradient(-90deg, #befcf7, #03948d);
  color: #fff;
}
.tc-title {
  background-image: linear-gradient(-90deg, #fff, #057996);
  color: #fff;
  border-radius: 3px;
}
.btn-title {
  background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
  color: #fff;
}
.card-title {
  background-image: linear-gradient(-90deg, #8edffd, #034c70);
  color: #fff;
}
.lbl {
  color: #1705e6;
}
.c-title1 {
  background-image: linear-gradient(-90deg, #dbdbdb, #012944);
  color: #fff;
}
.link-icon {
  color: #fff;
}
.btn-title2 {
  background: linear-gradient(-90deg, #30cfd0 0%, #330867 100%);
}

</style>